@media print {
    @page {
        /*size: 50mm 150mm;*/
        margin-top: 10mm !important;
        margin-bottom: 10mm !important;
    }

    select {
        height: 0px;
        display: none;
    }

    .form-select {
        display: none;
    }
}