.image-container {
    position: relative;
    overflow: hidden;
    /* width: 100%;
     max-width: 600px; Adjust as needed */
}

.image-container img {
    width: 100%;
    filter: blur(10px);
    /* brightness(50%)*/
    object-fit: cover;
    /* Ensures image covers the entire container */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    /* Full height of container */
}

.overlay {
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
}

.overlay button.btn {
    background-color: rgba(0, 0, 0, 0.3);
}

.image-container-follower {
    z-index: 0;
}